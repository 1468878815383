<template>
  <span
    v-if="!applicable"
    class="black-badge text-base font-medium mr-2 px-2.5 py-0.5 "
    v-tippy="{arrow : true, arrowType : 'round', animation : 'fade', size: 'large', allowHTML: true, placement : 'top'}"
    :content="'Only partial test'"
  > N/A</span>
  <span
    v-else-if="Math.round(matching * 100) >= 80"
    class="green-badge text-base font-medium mr-2 px-2.5 py-0.5 "
  > {{ Math.round(matching * 100) }} % </span>
  <span
    v-else-if="Math.round(matching * 100) < 80 && Math.round(matching * 100) >= 50"
    class="yellow-badge text-base font-medium mr-2 px-2.5 py-0.5 "
  > {{ Math.round(matching * 100) }} % </span>
  <span
    v-else
    class="red-badge text-base font-medium mr-2 px-2.5 py-0.5 "
  > {{ Math.round(matching * 100) }} % </span>
</template>

<script>

export default {
  props: {
    matching: {
      type: Number,
      required: true
    },
    applicable: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style>
.green-badge {
    background: #F1FFE6;
    border: 0.5px solid #08A133;
    border-radius: 4px;
    color: #08A133;
}

.red-badge {
    background: #F1FFE6;
    border: 0.5px solid #DA1E28;
    border-radius: 4px;
    color: #DA1E28;
}

.yellow-badge {
    background: #F1FFE6;
    border: 0.5px solid #EB6200;
    border-radius: 4px;
    color: #EB6200;
}

.black-badge {
    background: #F1FFE6;
    border: 0.5px solid #1f2937;
    border-radius: 4px;
    color: #1f2937;
}
</style>
