<template>
  <div class="dropdown dropdown-hover dropdown-left">
    <svg
      class="h-10 w-10"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
        clip-rule="evenodd"
      />
    </svg>
    <div
      tabindex="0"
      class="
        p-2
        shadow
        menu
        dropdown-content
        rounded-box
        bg-white
        w-96
      "
    >
      <h2 class="font-bold text-lg">
        Questions:
      </h2>
      <div
        class="flex flex-row"
        v-for="question in questions"
        :key="question"
      >
        <!-- below is necessary because daisyUI won't let the list discs appear -->
        <p>
          •
        </p>
        <p
          class="text-lg ml-1"
        >
          {{ question }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questions: {
      type: Array,
      required: true
    }
  }
}
</script>

<style>
</style>
