<template>
  <div class="flex flex-col w-full h-full p-5 self-awarness">
    <chart-tooltip
      ref="tooltip"
      type="spider"
      :has-play="true"
    />
    <div class="page-title">
      <h2>
        {{ content.title }}
      </h2>
      <p>
        {{ content.description }}
      </p>
    </div>

    <div class="flex items-center">
      <div class="indicators">
        <div
          class="result-para"
          v-if="newAverage"
        >
          <div>Tendency;</div> <strong>{{ newAverage[0][0] }}</strong>
        </div>
        <div
          v-if="newAverage"
          class="estimations"
        >
          <ul>
            <li class="green">
              <div>
                <span>Close</span>
              </div>
              <span class="ml-auto">
                <strong>{{ newAverage[1][0][1] }} of 8 </strong>
              </span>
            </li>
            <li class="yellow">
              <span>Underestimate</span><span class="ml-auto"><strong> {{ newAverage[1][1][1] }} of 8</strong></span>
            </li>
            <li class="red">
              <span>Overestimate</span><span class="ml-auto"><strong>{{ newAverage[1][2][1] }} of 8</strong></span>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex-auto w-64 mx-8 spider-box">
        <div class="w-52" />
        <div>
          <radar-chart
            v-if="loadedTooltip && options"
            key="selfAwarenessRadar"
            ref="chart"
            :width="725"
            :height="725"
            :chart-data="data"
            :options="options"
            :plugins="[$refs.tooltip.plugin]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { SelfAwareness } from '@/content/reportContent.json'

import { RadarChart } from 'vue-chart-3'

import { maxColor, estimateColor } from '../chartConfig'

import ChartTooltip from './ChartTooltip'

import { normStyling, candidateStyling } from '@/utils/chartUtils.js'
import { mapGetters } from 'vuex'

const stanineMap = {
  '-10': '',
  '-1': 1,
  7: 2,
  16: 3,
  31: 4,
  49.5: 5,
  68: 6,
  82.5: 7,
  92: 8,
  100: 9
}
const stanineMapReversed = {
  0: -30,
  1: -1,
  2: 7,
  3: 16,
  4: 31,
  5: 49.5,
  6: 68,
  7: 82.5,
  8: 92,
  9: 100
}

export default {
  components: {
    RadarChart,
    ChartTooltip
  },

  props: {
    chartData: {
      type: Array,
      required: true
    },
    pdf: {
      type: Boolean
    }
  },
  data () {
    return {
      content: SelfAwareness,
      maxColor,
      estimateColor,
      loadedTooltip: false,
      newAverage: null,
      options: null,
      baseChartOptions: {
        animation: (this.pdf) ? {
          duration: 0
        } : undefined,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              font: {
                size: 16
              }
            }
          }
        },
        scales: {
          r: {
            grid: {
              lineWidth: 2
            },
            pointLabels: {
              font: {
                size: 16,
                weight: 'bold'
              },
              color: label => {
                // color the labels depening on score. 5> green 5 black 5 < red
                if (this.data.datasets && this.data.datasets.length === 2) {
                  const esitmate = parseFloat(this.data.datasets[1].data[label.index])
                  const capacity = parseFloat(this.data.datasets[0].data[label.index])
                  if (capacity === 0) return 'black'
                  if (Math.abs(capacity - esitmate) < 1) {
                    return 'green'
                  } else if (capacity < esitmate) {
                    return 'red'
                  } else {
                    return '#caaa00'
                  }
                } else {
                  return 'black'
                }
              },
              callback: label => {
                if (label.includes('&')) {
                  const split = label.split('&')
                  split[0] = split[0] + ' &'
                  return split
                }
                return label
              }
            }
          }
        }
      }
    }
  },
  methods: {
    mapOldEstimates (estimate) {
      const maxEstimateValue = 10
      const minEstimateValue = 1
      const maxStanine = 9
      const minStaine = 1

      const slope = (maxStanine - minStaine) / (maxEstimateValue - minEstimateValue)

      return minEstimateValue + slope * (estimate - minEstimateValue)
    },
    getEstimationText (value) {
      const absValue = Math.abs(value)

      if (absValue < 2) {
        return this.content.estimationTexts.slightly
      } else if (absValue >= 2 && absValue < 3) {
        return this.content.estimationTexts.moderately
      } else {
        return this.content.estimationTexts.greatly
      }
    },
    calcTendency () {
      let over = 0
      let under = 0
      let close = 0

      this.chartData.forEach(el => {
        const { outcomeMax, comparativePerformanceLevel, stanine } = el

        if (el.placeholder) return

        // Check if the data is before or after method change for estimation
        let value = parseInt(comparativePerformanceLevel)
        if (outcomeMax !== undefined && outcomeMax != null) {
          value = this.mapOldEstimates(comparativePerformanceLevel)
        }

        if (el.stanine !== 0) {
          const p = value - stanine
          if (Math.abs(p) >= 0 && Math.abs(p) < 1) {
            close += 1
          } else if (p < 0) {
            under += 1
          } else if (p > 0) {
            over += 1
          }
        }
      })

      const tendencyArray = [[' Close', close], [' Underestimation', under], [' Overestimation', over]]

      const tendencyArraySorted = [...tendencyArray].sort((a, b) => a[1] - b[1])
      const divergent = tendencyArraySorted[1][1] === tendencyArraySorted[2][1]

      return [divergent ? [' Divergent'] : tendencyArraySorted[2], tendencyArray]
    }
  },
  mounted () {
    this.newAverage = this.calcTendency()

    // determine chart settings
    if (!this.chartData.every(({ outcomeMax }) => outcomeMax !== undefined && outcomeMax != null)) {
      // new data => use the new settings for the spider chart
      this.baseChartOptions.plugins.tooltip = {
        callbacks: {
          label: (ctx) => {
            if (stanineMap[ctx.parsed.r]) {
              return `${ctx.dataset.label}: ${stanineMap[ctx.parsed.r]}`
            }
            return `${ctx.dataset.label}: 0`
          }
        }
      }
      this.baseChartOptions.scales.r = {
        ...this.baseChartOptions.scales.r,
        max: 100,
        min: -30,
        ticks: {
          showLabelBackdrop: false,
          padding: 0,
          z: 1,
          stepSize: 0.5,
          callback (tickValue) {
            return stanineMap[tickValue.toString()]
          },
          font: {
            size: 12
          }
        }
      }
      this.options = this.baseChartOptions
    } else {
      // old data => use the old settings for the spider chart
      this.baseChartOptions.scales.r = {
        ...this.baseChartOptions.scales.r,
        beginAtZero: true,
        max: 9,
        ticks: {
          fonts: {
            stepSize: 1,
            size: 14
          }
        }
      }
      this.options = this.baseChartOptions
    }

    this.loadedTooltip = true
  },
  computed: {
    ...mapGetters({
      mainCandidate: 'REPORT/getMainCandidate'
    }),
    data () {
      return {
        labels: this.chartData.map(el => el.label),
        datasets: [
          {
            label: 'Candidate performance',
            data: this.chartData.map(el => {
              // check if we have new data and convert values
              if (!this.chartData.every(({ outcomeMax }) => outcomeMax !== undefined && outcomeMax != null)) {
                return stanineMapReversed[el.stanine]
              }
              return el.stanine
            }),
            ...normStyling
          },
          {
            label: "Candidate's estimation",
            data: this.chartData.map(({ comparativePerformanceLevel, outcomeMax, label }) => {
              if (outcomeMax !== undefined && outcomeMax != null) {
                return this.mapOldEstimates(comparativePerformanceLevel)
              }
              return stanineMapReversed[comparativePerformanceLevel] // convert values
            }),
            ...candidateStyling
          }
        ]
      }
    }
  }
}
</script>

<style>

</style>
