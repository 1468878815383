<!-- based on component from https://github.com/iNeoO/vue-multi-select -->

<template>
  <div class="select px-0">
    <button
      ref="buttonClick"
      type="button"
      class="flex w-full h-full justify-start pr-0 mr-0 pr-0"
      :class="`btn-select ${btnClass}`"
      :disabled="disabled"
      @click="toggleCheckboxes"
    >
      <div class="buttonLabel w-full flex align-middle text-left font-semibold text-base text-black ml-0.5 -mt-1">
        <span
          v-html="getBtnLabel"
          class="w-full"
        />
        <span class="caret float-right" />
      </div>
    </button>
    <div
      class="checkboxLayer w-full pb-2"
      :class="`${isOpen ? 'show' : ''} ${popoverClass}`"
      v-click-outside="externalClick"
      :style="getPosition"
    >
      <div class="checkBoxContainer">
        <ul
          class="selectList"
          v-for="(tab, index) in globalModel"
          v-show="idSelectedTab == index"
          :key="index"
        >
          <li
            v-for="(option, indexOptions) in tab[list]"
            :key="indexOptions"
            :class="[option[labelDisabled] ? 'disabled' : '', 'selectItem']"
            v-show="option.visible"
            @click="selectOption(option)"
            :style="cssSelected(option)"
          >
            <slot
              name="option"
              :option="option"
            >
              <span
                class="right margin-right-10"
                v-if="option[labelSelected]"
              >✓</span>
              <span class="margin-left-20">{{ option[labelName] }}</span>
            </slot>
          </li>
        </ul>
        <div
          v-if="!valueSelected || optionsAllHide"
          class="empty-tab"
        >
          {{ emptyTabText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    options: {
      type: Object,
      default: () => ({})
    },
    filters: {
      type: Array,
      default: () => []
    },
    selectOptions: {
      type: Array,
      default: () => []
    },
    eventName: {
      type: String,
      default: 'selectionChanged'
    },
    reloadInit: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => ([])
    },
    btnClass: {
      type: String,
      default: ''
    },
    popoverClass: {
      type: String,
      default: ''
    },
    btnLabel: {
      type: Function,
      default: () => ('multi-select')
    },
    position: {
      type: String,
      default: 'bottom-left'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledUnSelect: {
      type: Boolean,
      default: false
    },
    emptyTabText: {
      type: String,
      default: 'No Data'
    }
  },
  data () {
    return {
      valueSelected: [],
      simpleArray: false,
      multiSelect: null,
      groups: null,
      isOpen: false,
      globalModel: [],
      idSelectedTab: 0,
      searchInput: '',
      optionsAllHide: false,
      previousSelected: []
    }
  },
  created () {
    this.setConfig()
  },
  methods: {
    openMultiSelect () {
      this.manualClick = true
      this.isOpen = true
      this.openStatus(this.isOpen)
    },
    closeMultiSelect () {
      this.manualClick = true
      this.isOpen = false
      this.openStatus(this.isOpen)
    },
    setConfig () {
      this.multi = typeof (this.options.multi) !== 'undefined'
        ? this.options.multi : false
      this.groups = typeof (this.options.groups) !== 'undefined'
        ? this.options.groups : false
      this.list = this.options.labelList || 'list'
      this.labelName = this.options.labelName || 'name'
      this.labelValue = this.options.labelValue || this.labelName
      this.groupName = this.options.groupName || 'name'
      this.labelSelected = this.options.labelSelected || 'selected'
      this.labelDisabled = this.options.labelDisabled || 'disabled'
      this.cssSelected = this.options.cssSelected || (
        (option) => (option[this.labelSelected]
          ? {
            'font-weight': 'bold',
            color: '#5755d9'
          } : ''))
      this.init()
    },
    init () {
      const clone = this.cloneData(this.selectOptions)
      if (!this.groups) {
        if (typeof this.selectOptions[0] === 'string' ||
          typeof this.selectOptions[0] === 'number') {
          this.simpleArray = true
          this.globalModel = [{ [this.list]: this.prepareArray(clone) }]
        } else {
          this.globalModel = [{ [this.list]: clone }]
        }
      } else {
        if (typeof clone[0][this.list][0] === 'string' ||
        typeof clone[0][this.list][0] === 'number') {
          for (let i = 0; i < clone.length; i += 1) {
            clone[i][this.list] = this.prepareArray(clone[i][this.list])
          }
          this.simpleArray = true
        }
        this.globalModel = clone
      }
      this.initValues()
    },
    initValues () {
      this.valueSelected = []
      for (let i = 0; i < this.globalModel.length; i += 1) {
        for (let j = 0; j < this.globalModel[i][this.list].length; j += 1) {
          if (typeof this.globalModel[i][this.list][j][this.labelSelected] ===
            'boolean') {
            this.globalModel[i][this.list][j][this.labelSelected] = false
          } else {
            this.$set(this.globalModel[i][this.list][j], this.labelSelected, false)
            this.$set(this.globalModel[i][this.list][j], 'visible', true)
          }
          for (let k = 0; k < this.value.length; k += 1) {
            if (this.simpleArray &&
              this.globalModel[i][this.list][j][this.labelValue] === this.value[k]) {
              this.globalModel[i][this.list][j][this.labelSelected] = true
              this.valueSelected.push(this.globalModel[i][
                this.list][j][this.labelValue])
            } else if (!this.simpleArray &&
              this.globalModel[i][this.list][j][this.labelValue] ===
              this.value[k][this.labelValue]) {
              this.globalModel[i][this.list][j][this.labelSelected] = true
              const opt = { ...this.globalModel[i][this.list][j] }
              delete opt[this.labelSelected]
              delete opt.visible
              this.valueSelected.push(opt)
            }
          }
        }
      }
      this.filter()
      this.$emit('input', this.valueSelected.slice(0))
      this.$emit(this.eventName, this.valueSelected.slice(0))
    },
    toggleCheckboxes (event) {
      this.multiSelect = event.target
      if (this.multiSelect.className === 'buttonLabel') {
        this.multiSelect = this.multiSelect.parentNode
      }
      this.isOpen = !this.isOpen
      this.openStatus(this.isOpen)
    },
    externalClick (event) {
      if (this.isOpen && !this.manualClick) {
        let elem = event.target
        if (!!elem && elem.className === 'buttonLabel') {
          elem = elem.parentNode
        }
        if (!!elem && elem.isSameNode(this.multiSelect)) {
          return
        }
        this.isOpen = false
        this.openStatus(this.isOpen)
      }
      this.manualClick = false
    },
    /* eslint no-param-reassign: ["error", { "props": false }] */
    selectOption (option) {
      if (option[this.labelDisabled]) {
        return
      }
      if (!option[this.labelSelected]) {
        this.previousSelected.push(this.cloneData(this.valueSelected))
        if (!this.multi) {
          this.deselctAll()
          this.valueSelected = []
          this.externalClick({ path: [] })
        }
        this.pushOption(option)
        this.$emit('input', this.valueSelected.slice(0))
        this.$emit(this.eventName, this.valueSelected.slice(0))
      } else {
        if (!this.multi && this.disabledUnSelect) {
          return
        }
        this.previousSelected.push(this.cloneData(this.valueSelected))
        this.popOption(option)
        this.$emit('input', this.valueSelected.slice(0))
        this.$emit(this.eventName, this.valueSelected.slice(0))
      }
      option[this.labelSelected] = !option[this.labelSelected]
      this.filter()
    },
    pushOption (option) {
      if (this.simpleArray) {
        this.valueSelected.push(option[this.labelValue])
      } else {
        const opt = { ...option }
        delete opt[this.labelSelected]
        delete opt.visible
        this.valueSelected.push(opt)
      }
    },
    popOption (opt) {
      for (let i = 0; i < this.valueSelected.length; i += 1) {
        if (this.valueSelected[i][this.labelValue] === opt[this.labelValue] ||
          (this.simpleArray && this.valueSelected[i] === opt[this.labelValue])) {
          this.valueSelected.splice(i, 1)
          return
        }
      }
    },
    selectTab (id) {
      this.idSelectedTab = id
      this.searchfn()
    },
    searchfn () {
      let allHide = true
      for (let i = 0; i < this.globalModel[this.idSelectedTab][this.list].length;
        i += 1) {
        if (~this.globalModel[this.idSelectedTab][this.list][i][this.labelName]
          .toLowerCase().indexOf(
            this.searchInput.toLowerCase()
          )) {
          allHide = false
          this.globalModel[this.idSelectedTab][this.list][i].visible = true
        } else {
          this.globalModel[this.idSelectedTab][this.list][i].visible = false
        }
      }
      this.optionsAllHide = allHide
      this.filter()
    },
    clearSearch () {
      this.searchInput = ''
      this.searchfn()
    },
    selectCurrent (option) {
      this.previousSelected.push(this.cloneData(this.valueSelected))
      for (let i = 0; i < this.globalModel[this.idSelectedTab][this.list].length;
        i += 1) {
        if (this.globalModel[this.idSelectedTab][this.list][i].visible &&
          !this.globalModel[this.idSelectedTab][this.list][i][this.labelDisabled] &&
          option.func(this.globalModel[this.idSelectedTab][this.list][i])) {
          if (!option.selectAll) {
            if (!this.globalModel[this.idSelectedTab][this.list][i][this.labelSelected]) {
              this.globalModel[this.idSelectedTab][this.list][i][this.labelSelected] = true
              this.pushOption(this.globalModel[this.idSelectedTab][this.list][i])
            }
          } else if (this.globalModel[this.idSelectedTab][this.list][i][this.labelSelected]) {
            this.globalModel[this.idSelectedTab][this.list][i][this.labelSelected] = false
            this.popOption(this.globalModel[this.idSelectedTab][this.list][i])
          }
        }
      }
      this.$emit('input', this.valueSelected.slice(0))
      this.$emit(this.eventName, this.valueSelected.slice(0))
      option.selectAll = !option.selectAll
      this.filter()
    },
    filter () {
      for (let i = 0; i < this.filters.length; i += 1) {
        let allSelected = true
        for (let j = 0; j < this.globalModel[this.idSelectedTab][this.list].length;
          j += 1) {
          if (this.globalModel[this.idSelectedTab][this.list][j].visible &&
            this.filters[i].func(
              this.globalModel[this.idSelectedTab][this.list][j]
            ) &&
              !this.globalModel[this.idSelectedTab][this.list][j][this.labelDisabled] &&
              !this.globalModel[this.idSelectedTab][this.list][j][this.labelSelected]) {
            allSelected = false
            break
          }
        }
        this.filters[i].selectAll = allSelected
      }
    },
    deselctAll () {
      for (let i = 0; i < this.globalModel.length; i += 1) {
        for (let j = 0; j < this.globalModel[i][this.list].length; j += 1) {
          if (!this.globalModel[i][this.list][j][this.labelDisabled]) {
            this.globalModel[i][this.list][j][this.labelSelected] = false
          }
        }
      }
    },
    prepareArray (value) {
      return value.map((elem) => ({ [this.labelValue]: elem }))
    },
    cloneData (value) {
      if (Array.isArray(value)) {
        return value.map(this.cloneData)
      } if (value && typeof value === 'object') {
        const res = {}
        const keys = Object.keys(value)
        for (let i = 0; i < keys.length; i += 1) {
          res[keys[i]] = this.cloneData(value[keys[i]])
        }
        return res
      }
      return value
    },
    openStatus (status) {
      const event = status ? 'open' : 'close'
      this.$emit(event)
    },
    compareArrayObject (value1, value2, key) {
      if (value1.length !== value2.length) return false
      for (let i = 0; i < value1.length; i += 1) {
        if (value1[i][key] !== value2[i][key]) {
          return false
        }
      }
      return true
    },
    compareSimpleArray (value1, value2) {
      if (value1.length !== value2.length) return false
      for (let i = 0; i < value1.length; i += 1) {
        if (value1[i] !== value2[i]) {
          return false
        }
      }
      return true
    }
  },
  computed: {
    getBtnLabel () {
      return this.btnLabel(this.valueSelected)
    },
    getPosition () {
      if (this.multiSelect) {
        const btnHeight = this.multiSelect.offsetHeight
        const positions = this.position.split('-')
        const style = {
          [positions[1]]: 0
        }
        if (positions[0] === 'top') {
          style.bottom = `${btnHeight}px`
        }
        return style
      }
      return {}
    },
    getButtonList () {
      return !!this.filters && this.multi && this.filters
    }
  },
  watch: {
    selectOptions: {
      handler () {
        this.setConfig()
      },
      deep: true
    },
    reloadInit: {
      handler (value) {
        if (value) {
          this.initValues()
          this.$emit('vueMultiSelectInited')
        }
      }
    },
    value: {
      handler (newVal, oldval) {
        if (oldval && newVal && this.valueSelected) {
          if (this.simpleArray &&
            !this.compareSimpleArray(newVal, this.valueSelected)) {
            this.initValues()
          } else if (!this.compareArrayObject(
            newVal, this.valueSelected, this.labelName
          )) {
            this.initValues()
          }
        }
      },
      deep: true
    }
  },
  directives: {
    'click-outside': {
      bind (el, binding) {
        const { bubble } = binding.modifiers
        const ua = navigator.userAgent
        const event = (ua.match(/iPad|iPhone/i)) ? 'touchstart' : 'click'
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.vueClickOutside = handler
        document.addEventListener(event, handler)
      },
      unbind (el) {
        const ua = navigator.userAgent
        const event = (ua.match(/iPad|iPhone/i)) ? 'touchstart' : 'click'
        document.removeEventListener(event, el.vueClickOutside)
        el.vueClickOutside = null
      }
    }
  }
}
</script>
<style scoped>
/* ! vertical layout */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background: #eee;
  border-radius: 10px;
}

.select {
  display: inline-block;
  text-align:left;
  position: relative;
  background-image:none;
}

.select .vertical {
  float: none;
}

/* ! horizontal layout */

.select .horizontal:not(.selectGroup) {
  float: left;
  text-align:left;
  position: relative;
}

/* ! create a "row" */

.select .line {
  box-sizing: content-box;
  max-height: 30px;
  overflow: hidden;
  padding: 2px 0 4px 0;
  position: relative;
}

/* ! create a "column" */

.select .acol {
  display: inline-block;
  min-width: 12px;
}

/* ! */

.select .inlineBlock {
  display: inline-block;
}

/* the select button */

.select>button {
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  color: #555;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  min-height: 31px;
  padding: 1px 8px 1px 8px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  white-space: normal;
  background-color: #fff;
  /*background-image: linear-gradient(#fff, #f7f7f7);      */
}

/* button: hover */

.select>button:hover {
  background-color: #f7f7f7;
}

.select>button:disabled {
  background-color: #f7f7f7;
}

/* button: clicked */

.select .buttonClicked {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset, 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* labels on the button */

.select .buttonLabel {
  word-break: break-word;
  display: inline-block;
  padding: 0px 0px 0px 0px;
}

/* downward pointing arrow */

.select .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 6px 8px 0px 0px;
  vertical-align: middle;
  border-top: 4px solid #333;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 0 dotted;
}

/* the main checkboxes and helper layer */

.select .checkboxLayer {
  background-color: #fff;
  position: absolute;
  z-index: 999;
  border: solid lightgrey;
  border-width: 1px 1px 1px 1px;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  min-width: 278px;
  display: none;
}

/* container of helper elements */

.select .helperContainer {
  padding: 8px 8px 0px 8px;
}

/* helper buttons (select all, none, reset); */

.select .helperButton:not( .reset) {
  margin-right: 4px;
}

.select .helperButton {
  display: inline;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  height: 26px;
  font-size: 13px;
  border-radius: 2px;
  color: #666;
  background-color: #ffffff;
  line-height: 1.6;
  margin: 0 0 8px 0;
}

.select .historyButton {
  float: right;
  display: inline;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  height: 26px;
  font-size: 13px;
  border-radius: 2px;
  color: #666;
  background-color: #ffffff;
  line-height: 1.6;
}

.right {
  float: right;
}

.margin-right-10 {
  margin-right: 10px
}

/* clear button */

@-moz-document url-prefix() {
  .select .clearButton {
    height: 24px!important;
  }
}

.select .clearButton {
  box-sizing: inherit;
  position: absolute;
  display: inline;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  height: 22px;
  width: 22px;
  font-size: 13px;
  border-radius: 2px;
  color: #666;
  background-color: #ffffff;
  line-height: 1.4;
  right: 0px;
  top: 2px;
}

/* filter */

.select .inputFilter {
  border-radius: 2px;
  border: 1px solid #ccc;
  height: 26px;
  font-size: 14px;
  width: 100%;
  min-width: 320px;
  padding-left: 7px;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  color: #888;
  margin: 0 0 8px 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

/* helper elements on hover & focus */

.select .clearButton:hover,
.select .helperButton:hover,
.select .historyButton:hover {
  border: 1px solid #ccc;
  color: #999;
  background-color: #f1f1f1;
}

.select .clearButton:focus,
.select .helperButton:focus,
.select .inputFilter:focus,
.select .historyButton:focus {
  border: 1px solid #999;
  outline: 0;
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, .065), 0 0 5px rgba(102, 175, 233, .6);
  box-shadow: inset 0 0 1px rgba(0, 0, 0, .065), 0 0 5px rgba(102, 175, 233, .6);
}

/* container of single select items */

.select .checkBoxContainer {
  display: block;
  padding-top: 5px;
  overflow: hidden;
  max-height: 300px;
  min-height: 80px;
  overflow-y: auto;

}

.margin-left-20 {
  margin-left: 20px;
}

/* ! to show / hide the checkbox layer above */

.select .show {
  display: block;
}

/* item labels */

.select .selectItem {
  display: block;
  padding: 3px;
  font-size: 13px;
  color: black;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  position: relative;
  min-width: 278px;
  min-height: 32px;
  margin-top: 0;
}

/* item labels when not active */

.select .selectItemDeactive {
  display: block;
  padding: 3px;
  color: #444;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  position: relative;
  min-width: 278px;
  min-height: 32px;
}

/* item labels focus on mouse hover */

.select .selectItemDeactive:hover {
  cursor: not-allowed;
}

/* Styling on selected items */

.select .selectItem.selected {
  background-color: #e9e9e9;
  color: #555;
  cursor: pointer;
  border-top: 1px solid #e4e4e4;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #d9d9d9;
}

.select .selectItem .acol label {
  display: inline-block;
  padding-right: 30px;
  margin: 0px;
  font-weight: normal;
  line-height: normal;
}

/* item labels focus on mouse hover */

.select .selectItem:hover,
.select .selectGroup:hover {
  cursor: pointer;
  background-color: #f5f7fa;
}

/* item labels focus using keyboard */

.select .selectFocus {
  cursor: pointer;
}

/* change mouse pointer into the pointing finger */

.select .selectItem span:hover,
.select .selectGroup span:hover {
  cursor: pointer;
}

/* ! group labels */

.select .selectGroup {
  display: block;
  clear: both;
}

/* right-align the tick mark (&#10004;) */

.select .tickMark {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 10px;
}

/* hide the original HTML checkbox away */

.select .checkbox {
  color: #ddd;
  position: absolute;
  left: -9999px;
  cursor: pointer;
}

/* checkboxes currently disabled */

.select .disabled,
.select .disabled:hover,
.select .disabled label input:hover~span {
  color: #c4c4c4;
  cursor: not-allowed;
}

/* If you use images in button / checkbox label, you might want to change the image style here. */

.select img {
  vertical-align: middle;
  margin-bottom: 0px;
  max-height: 22px;
  max-width: 22px;
}

.select .group {
  font-weight: 600;
  font-size: 14px;
}

.select .sousGroup {
  margin-left: 15px;
}

.tab {
  padding-left: 0;
  align-items: center;
  border-bottom: .05rem solid #e7e9ed;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  margin: .2rem 0 .15rem 0;
}

.tab-block {

}

.tab-item {
  margin-top: 0;
}

.tab .tab-item.active span, .tab .tab-item span.active {
  border-bottom-color: #6E7991;
  color: #6E7991;
}

.tab .tab-item span {
  border-bottom: .1rem solid transparent;
  color: inherit;
  display: block;
  margin: 0 .4rem 0 0;
  padding: .4rem .4rem .3rem .4rem;
  text-decoration: none;
}

.select .empty-tab {
  min-height: 80px;
  text-align: center;
  padding-top: 30px;
  font-size: 15px;
  color: rgb(116, 116, 116);
}

.selectList {
  margin: 0;
  list-style: inside disc;
  padding-left: 0px;
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: bold;
}
</style>
