<template>
  <div class="flex flex-col w-full h-full p-5">
    <chart-tooltip
      ref="tooltip"
      type="bar"
    />
    <bar-tooltip ref="barTooltip" />

    <div class="page-title">
      <h2>
        {{ content.title }}
      </h2>
      <p>
        {{ content.description }}<br>
        {{ content.description2 }}
      </p>
    </div>
    <div class="flex items-center justify-center flex-auto">
      <img
        src="@/assets/reports/master.webp"
        class="hidden mr-2 w-72 h-72 lg:block"
      >
      <bar-chart
        v-if="loadedTooltip"
        :width="700"
        :height="350"
        :chart-data="data"
        :options="options"
        :plugins="[$refs.tooltip.plugin]"
      />
    </div>
  </div>
</template>

<script>

import { QualityMeasures } from '@/content/reportContent.json'

import ChartTooltip from './ChartTooltip.vue'
import BarTooltip from './BarTooltip.vue'

import { BarChart } from 'vue-chart-3'

export default {
  components: {
    BarChart,
    ChartTooltip,
    BarTooltip
  },
  props: {
    chartData: {
      type: Array,
      required: true
    },
    pdf: {
      type: Boolean
    }
  },
  data () {
    return {
      content: QualityMeasures,
      loadedTooltip: false,
      sortedData: [],
      options: {
        animation: (this.pdf) ? {
          duration: 0
        } : undefined,
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: null
          },
          datalabels: {
            display: true,
            anchor: (context) => {
              const index = context.dataIndex
              const value = context.dataset.data[index]
              return (value > (context.dataIndex === 3 ? 2 : 4) ? '#center' : 'end')
            },
            align: (context) => {
              const index = context.dataIndex
              const value = context.dataset.data[index]
              return (value > (context.dataIndex === 3 ? 2 : 4) ? '#center' : 'end')
            },
            color: (context) => {
              const index = context.dataIndex
              const value = context.dataset.data[index]
              return (value > (context.dataIndex === 3 ? 2 : 4) ? 'white' : 'grey')
            },
            font: {
              size: 14
            },
            formatter: (_, context) => {
              const label = this.data.labels[context.dataIndex]
              return label === 'Accuracy' ? 'In Impulse Control' : 'In Creativity & Cognitive Flexibility'
            }
          }
        },
        scales: {
          x: {
            ticks: {
              font: {
                size: 14
              }
            },
            max: 9,
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            },
            ticks: {
              font: {
                size: 16
              },
              color: 'black',
              callback: function (value) {
                const label = this.getLabelForValue(value)
                if (label.includes('&')) {
                  const split = label.split('&')
                  split[0] = `${split[0]} &`
                  return split
                }
                return label
              }
            }
          }
        }
      }
    }
  },
  mounted () {
    this.sortedData = this.chartData.slice()
    this.sortedData.sort((a, b) => parseInt(b.stanine) - parseInt(a.stanine))

    this.loadedTooltip = true
    this.options.plugins.tooltip.external = this.$refs.barTooltip.tooltipHandler
  },
  computed: {
    data () {
      return {
        labels: this.sortedData.map(el => el.label),
        datasets: [
          {
            label: 'The Candidate',
            data: this.sortedData.map(el => el.stanine),
            backgroundColor: 'black'
          }
        ]

      }
    }
  }
}
</script>
