<template>
  <div class="flex flex-col w-full h-full p-5">
    <div class="page-title mb-8">
      <h2>
        {{ content.title }}
      </h2>
    </div>
    <div class="flex flex-row h-full">
      <div class="candidate-info-main w-1/5 text-white">
        <div
          class="position-padding relative top-12"
          v-if="mainCandidate"
          ref="candidateInfoDiv"
        >
          <p
            class="text-2xl font-semibold py-4 leading-10"
            v-if="!showInformation"
          >
            Candidate name
          </p>
          <p
            v-else
            class="text-2xl font-semibold py-4 leading-10"
          >
            {{ mainCandidate.name }}
          </p>
          <p
            class="text-xl font-normal py-2 leading-10 flex items-center"
            v-if="!showInformation"
          >
            Age: <span class="hide-back" />
          </p>
          <p
            class="text-xl font-normal py-2 leading-10"
            v-else
          >
            Age: {{ mainCandidate.current_age }}
          </p>
          <p
            class="text-xl font-normal py-2 leading-10 flex items-center"
            v-if="!showInformation"
          >
            Gender:    <span class="hide-back" />
          </p>
          <p
            class="text-xl font-normal py-2 leading-10"
            v-else
          >
            Gender: {{ mainCandidate.gender }}
          </p>
          <p
            class="text-xl font-normal py-2"
            v-if="mainCandidate.touchScreenProficiency"
          >
            Touchscreen proficiency: {{ touchScreenProficiency }}
          </p>
          <p class="text-xl font-normal py-2">
            Candidate ID : {{ mainCandidate.alt_id }}
          </p>
          <show-details-btn
            class="self-center mt-32"
            v-model="showInformation"
            :main="true"
          />
        </div>
      </div>
      <div class="w-3/4 px-20">
        <div>
          <div class="flex flex-col mt-20">
            <candidate-state
              :is-sub-report="false"
              :test-issues="testIssues"
              :all-issues="allIssues"
              :main-candidate="mainCandidate"
              :is-pdf="isPdf"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { CandidateInfo } from '@/content/reportContent.json'

import ShowDetailsBtn from '@/modules/Scoreboard/components/ShowDetailsBtn.vue'
import CandidateState from '@/components/reports/CandidateState.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    ShowDetailsBtn,
    CandidateState
  },
  props: {
    anonymized: {
      type: Boolean,
      required: false,
      default: true
    },
    testIssues: {
      type: Array,
      required: false,
      default: null
    },
    fromOtp: {
      type: Boolean,
      required: false,
      default: false
    },
    isPdf: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      content: CandidateInfo,
      showInformation: false,
      width: null,
      state: 0,
      allIssues: []
    }
  },
  computed: {
    ...mapGetters({
      mainCandidate: 'REPORT/getMainCandidate',
      candidates: 'SCOREBOARD/getCandidates',
      issues: 'SCOREBOARD/getCandidatesTestIssues'
    }),
    dyslexia () {
      if (this.mainCandidate.dyslexia) {
        return (this.mainCandidate.dyslexia) ? 'Yes' : 'No'
      }
      return ''
    },
    touchScreenProficiency () {
      if (this.mainCandidate.touchScreenProficiency) {
        return CandidateInfo.TouchScreenProficiency[this.mainCandidate.touchScreenProficiency]
      }
      return ''
    }
  },
  created () {
    this.showInformation = !this.anonymized
  },
  async mounted () {
    this.width = this.$refs.candidateInfoDiv.clientWidth
    if (!this.fromOtp) {
      await this.$store.dispatch('SCOREBOARD/getCandidatesTestIssues', this.$route.query.campaignId)
      this.allIssues = this.issues.filter(el => el.user_id === this.mainCandidate.user_id)
    }
  }
}
</script>

<style>

</style>
