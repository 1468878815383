<template>
  <div
    class="w-full h-full"
    :class="{ 'flex flex-col ': !pdf }"
  >
    <div
      v-if="isDownloading"
      class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-80"
    >
      <button class=" btn btn-lg btn-circle btn-ghost loading" />
    </div>
    <div
      v-if="expired"
      class="flex items-center justify-center flex-auto"
    >
      <h5>
        Expired
      </h5>
    </div>
    <div
      v-else
      class="w-full h-full"
      :class="{ 'flex flex-col ': !pdf }"
      @click="closeReportModals = !closeReportModals"
    >
      <horizontal-report-menu
        v-if="!expired"

        :report-result-pages="reportResultPages"
        :report-info-pages="reportInfoPages"
        v-model="page"

        @download="downloadReport"
        @downloadRawDataReport="downloadRawDataReport"
        @share="showShareModal = true"

        :campaign-id="campaignId"
        :from-otp="!!otp"
        :allow-raw-data-report="!!isLoggedAdminIn"

        :close-report-modals="closeReportModals"
        :loaded-data="loadedData"
      />
      <div
        class="z-0 w-full"
        :class="{ 'flex-auto': !pdf, ' h-full': pdf }"
      >
        <!-- <welcome-page
          :show-no-results-msg="false"
          :pdf="pdf"
          v-show="page == 0 || pdf"
        /> -->

        <about-page v-show="page === 0 || pdf" />
        <about-the-norm-page
          v-show="page === 1 || pdf"
        />

        <scales-page v-show="page === 2 || pdf" />

        <game-analysis-page v-show="page === 3 || pdf" />

        <executive-summary-page
          :anonymized="anonymized"
          v-if="page === 4 || (pdf && loadedData)"
          :pdf="pdf"
          :chart-data="mainMeasures"
        />
        <candidate-info-page
          :anonymized="anonymized"
          v-if="page === 5 || pdf"
          :test-issues="allIssues"
          :from-otp="!!otp"
          :is-pdf="pdf"
        />
        <strengths-page
          v-if="page === 6 || (pdf && loadedData)"
          :chart-data="mainMeasures"
          :pdf="pdf"
          :from-otp="!!otp"
          :test-issues="allIssues"
          :low-score-comments="lowScoreComments"
        />
        <span v-if="comparisonPage">
          <span v-if="!disableQualityMeasures">
            <comparison-page
              v-if="page === 7 || (pdf && loadedData)"
              :chart-data="mainMeasures"
              :pdf="pdf"
              :from-otp="!!otp"
              :football-profiles="footballProfiles"
            />
            <quality-measures-page
              v-if="page === 8 || (pdf && loadedData)"
              :chart-data="qualityMeasures"
            />
            <performance-complexity-page
              v-if="page === 9 || (pdf && loadedData)"
              :chart-data="easyHardMeasures"
              :pdf="pdf"
            />
            <self-awareness-page
              v-if="page === 10 || (pdf && loadedData)"
              :chart-data="mainMeasures"
            />

          </span>
          <span v-else>
            <comparison-page
              v-if="page === 7 || (pdf && loadedData)"
              :chart-data="mainMeasures"
              :pdf="pdf"
              :from-otp="!!otp"
              :football-profiles="footballProfiles"
            />

            <performance-complexity-page
              v-if="page === 8 || (pdf && loadedData)"
              :chart-data="easyHardMeasures"
              :pdf="pdf"
            />
            <self-awareness-page
              v-if="page === 9 || (pdf && loadedData)"
              :chart-data="mainMeasures"
            />

          </span>
        </span>
        <span v-else>
          <span v-if="!disableQualityMeasures">
            <quality-measures-page
              v-if="page === 7 || (pdf && loadedData)"
              :chart-data="qualityMeasures"
            />
            <performance-complexity-page
              v-if="page === 8 || (pdf && loadedData)"
              :chart-data="easyHardMeasures"
              :pdf="pdf"
            />
            <self-awareness-page
              v-if="page === 9 || (pdf && loadedData)"
              :chart-data="mainMeasures"
            />

          </span>
          <span v-else>
            <performance-complexity-page
              v-if="page === 7 || (pdf && loadedData)"
              :chart-data="easyHardMeasures"
              :pdf="pdf"
            />
            <self-awareness-page
              v-if="page === 8 || (pdf && loadedData)"
              :chart-data="mainMeasures"
            />

          </span>
        </span>
      </div>
    </div>
    <share-modal
      :open="showShareModal"
      :candidate="mainCandidate"
      @close="showShareModal = false"
      :campaign-id="this.campaignId"
    />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import HorizontalReportMenu from '@/components/reports/HorizontalReportMenu.vue'

import { About, AboutTheNorm, CandidateInfo, Scales, GameAnalysis, ExecutiveSummary, CognitiveProfileStrengths, AbilityComparisonRadar, QualityMeasures, PerformanceComplexity, SelfAwareness } from '@/content/reportContent.json'

import StrengthsPage from '../components/StrengthsPage.vue'
import ComparisonPage from '../components/ComparisonPage.vue'
import QualityMeasuresPage from '../components/QualityMeasuresPage.vue'
import AboutPage from '../components/AboutPage.vue'
import GameAnalysisPage from '../components/GameAnalysisPage.vue'
import CandidateInfoPage from '../components/CandidateInfoPage.vue'
import PerformanceComplexityPage from '../components/PerformanceComplexityPage.vue'
import SelfAwarenessPage from '../components/SelfAwarenessPage.vue'
import AboutTheNormPage from '../components/AboutTheNormPage.vue'
import ScalesPage from '../components/ScalesPage.vue'
import ShareModal from '../components/ShareModal.vue'
import ExecutiveSummaryPage from '../components/ExecutiveSummaryPage.vue'

import download from 'downloadjs'

export default {
  components: {
    StrengthsPage,
    HorizontalReportMenu,
    ComparisonPage,
    QualityMeasuresPage,
    AboutPage,
    GameAnalysisPage,
    CandidateInfoPage,
    PerformanceComplexityPage,
    SelfAwarenessPage,
    AboutTheNormPage,
    ScalesPage,
    ShareModal,
    ExecutiveSummaryPage
  },

  props: {
    userId: {
      type: String,
      required: true
    },
    comparisonPage: Boolean,
    campaignId: {
      type: String,
      required: false,
      default: null
    },
    pdf: {
      type: Boolean,
      required: false,
      default: false
    },
    organisationId: {
      type: String,
      required: false,
      default: null
    },
    otp: {
      type: String,
      required: false,
      default: null
    },
    anonymized: {
      type: Boolean,
      required: false,
      default: true
    },
    footballProfiles: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      page: 4,
      expired: false,
      showCheckoutInfo: false,
      hostedPage: null,
      loadedData: false,
      isDownloading: false,
      mainCandidateName: null,
      showShareModal: false,
      allIssues: [],
      closeReportModals: false
    }
  },

  async created () {
    const res = await this.$store.dispatch('REPORT/getResults', { id: this.userId, otp: this.otp, campaignId: this.campaignId })
    if (res instanceof Error) {
      this.expired = true
    } else if (res.message === 'needs_checkout' && !res.hostedPage) {
      this.expired = true
    } else {
      await this.initReport()
    }

    this.loadedData = true
    console.log('pdf and loaded data?', this.pdf, this.loadedData)
    console.log('expired and pdf?', !this.expired && !this.pdf)
  },
  watch: {
    page () {
      if (this.page < 0) this.page = 0
      if (this.page > this.maxPages - 1) this.page = this.maxPages - 1
    }
  },
  computed: {
    ...mapGetters({
      mainMeasures: 'REPORT/getMainMeasures',
      qualityMeasures: 'REPORT/getQualityMeasures',
      easyHardMeasures: 'REPORT/getEasyHardMeasures',
      mainCandidate: 'REPORT/getMainCandidate',
      isLoggedAdminIn: 'ADMIN/isLoggedAdminIn',
      issues: 'SCOREBOARD/getCandidatesTestIssues',
      lowScoreComments: 'SCOREBOARD/getLowScoreComments'
    }),
    noResults () {
      return this.$store.state.REPORT.candidates.length === 0
    },
    disableQualityMeasures () {
      if (this.mainCandidate != null) {
        return !this.qualityMeasures.length > 0
      }
      return true
    },
    maxPages () {
      if (this.comparisonPage) {
        if (this.disableQualityMeasures) {
          return 10
        } else {
          return 11
        }
      } else {
        if (this.disableQualityMeasures) {
          return 9
        } else {
          return 10
        }
      }
    },
    reportResultPages () {
      // Pages regarding the test results
      return [
        ExecutiveSummary,
        CandidateInfo,
        CognitiveProfileStrengths,
        ...(this.comparisonPage ? [AbilityComparisonRadar] : []),
        ...(!this.disableQualityMeasures ? [QualityMeasures] : []),
        PerformanceComplexity,
        SelfAwareness
      ]
    },
    reportInfoPages () {
      // Pages about the report/test in general
      return [
        About,
        AboutTheNorm,
        Scales,
        GameAnalysis
      ]
    },
    data () {
      return {
        labels: this.mainMeasures.map(el => el.label),
        datasets: [
          {
            label: 'The Candidate',
            data: this.mainMeasures.map(el => el.stanine),
            barThickness: 56
          }
        ]
      }
    }
  },
  methods: {
    async initReport () {
      if (this.comparisonPage) {
        // if otp we dont need the other candidates data
        if (!this.otp) {
          await this.$store.dispatch('REPORT/getCandidates', this.campaignId)
        }

        this.mainCandidateName = this.mainCandidate.name || `Candidate ${this.mainCandidate.alt_id}`
      }

      if (!this.otp) {
        await this.$store.dispatch('SCOREBOARD/getCandidatesTestIssues', this.campaignId)
        this.allIssues = this.issues.filter(el => el.user_id === this.userId)
        if (this.allIssues && this.allIssues.length > 0) {
          await this.$store.dispatch('SCOREBOARD/getLowScoreComments', { test_session_id: this.allIssues[0].test_session_id, user_id: this.allIssues[0].user_id })
        }
      }
    },
    async downloadReport (anonymized) {
      this.isDownloading = true
      const { data, headers } = await this.$api.getPdfReport(this.userId, this.campaignId, anonymized, this.otp)
      const contentType = headers['content-type']
      download(data, `Full cognitive report ${anonymized ? `Candidate ${this.mainCandidate.alt_id}` : this.mainCandidateName}`, contentType)
      this.isDownloading = false
    },

    async downloadRawDataReport () {
      this.isDownloading = true
      const { data, headers } = await this.$api.getRawDataReport(this.userId, this.campaignId)

      const contentType = headers['content-type']
      download(data, '', contentType)

      this.isDownloading = false
    }
  },
  destroyed () {
    this.$store.dispatch('REPORT/empty')
  }
}
</script>

<style lang="scss">@import '../report.scss';
// Make the scrollbar always visible when page is overflowing
  ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
</style>
