<template>
  <div class="flex flex-col w-full h-full p-5">
    <div class="page-title">
      <h2>
        {{ content.navigationTitle }}
      </h2>
      <p class="mb-10" /><h5 class="font-medium my-5">
        {{ content.title1 }}
      </h5>
      <p>
        {{ content.paragraph1 }}
      </p>
      <p class="mb-10">
        {{ content.paragraph2 }}
      </p>
      <h5 class="font-medium my-5">
        {{ content.title2 }}
      </h5>
      <p class="mb-10">
        {{ content.paragraph3 }}
      </p>
      <h5 class="font-medium my-5">
        {{ content.title3 }}
      </h5>
      <p>
        {{ content.paragraph4 }}
      </p>
    </div>
  </div>
</template>

<script>

import { AboutTheNorm } from '@/content/reportContent.json'

export default {
  data () {
    return {
      content: AboutTheNorm
    }
  }
}
</script>

  <style>
  </style>
