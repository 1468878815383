<template>
  <div class="w-full p-5 performance-pressure breaker">
    <div class="page-title">
      <h2>
        {{ content.title }}
        <p
          class="text-xl"
          style="display: none;"
        >
          <question-icon
            v-if="pdf"
            class="float-right"
            :questions="content.questions"
          />
        </p>
        <span v-if="pdf && showSecondPagePdf">(page 1/2)</span>
      </h2>
      <p
        class="items-start text-xl"
        style="display: none;"
      >
        {{ content.description }}
      </p>
    </div>
    <!-- show for full report -->
    <div v-if="!missingMeasures">
      <div class="flex gap-14 gap-y-14 breaker">
        <div class="flex items-center w-2/4 my-8 full-chart">
          <div class="grid flex-auto grid-cols-2 grid-rows-3 mx-auto md:grid-rows-2 md:grid-cols-3 xl:grid-rows-1 xl:grid-cols-5">
            <bar-chart
              data-measure="attention"
              v-if="chartData.attention"
              :width="chartWidth"
              :height="chartHeight"
              :chart-data="attention"
              :options="createChartOptions('Attention')"
            />
            <bar-chart
              class="pt-5"
              data-measure="memory"
              v-if="chartData.memory"
              :width="chartWidth"
              :height="chartHeight"
              :chart-data="workingMemory"
              :options="createChartOptions(['Short-term &', 'Working Memory'])"
            />
            <bar-chart
              v-if="chartData.impulseControl"
              data-measure="impulseControl"

              :width="chartWidth"
              :height="chartHeight"
              :chart-data="impulseControl"
              :options="createChartOptions('Impulse Control')"
            />
            <bar-chart
              class="pt-5"
              data-measure="creativityCognitiveFlexibility"

              v-if="chartData.creativityCognitiveFlexibility"
              :width="chartWidth"
              :height="chartHeight"
              :chart-data="creativityCognitiveFlexibility"
              :options="createChartOptions(['Creativity &', 'Cognitive Flexibility'])"
            />
            <bar-chart
              data-measure="strategicThinking"

              v-if="chartData.strategicThinking"
              :width="chartWidth"
              :height="chartHeight"
              :chart-data="strategicThinking"
              :options="createChartOptions('Strategic Thinking')"
            />
          </div>
        </div>

        <div class="w-2/4 my-8 single-chart attention">
          <div class="box-title">
            Attention
          </div>
          <div class="flex items-center p-4">
            <div class="left-bar">
              <bar-chart
                data-measure="attention"

                v-if="chartData.attention"
                :height="chartHeight"

                :chart-data="attention"
                :options="chartOptionsSeperate"
              />
            </div>
            <div
              v-if="attention.behavioralExpression"
              class="w-2/3 p-4"
            >
              <p><strong>Expression</strong></p>
              <p>{{ attention.behavioralExpression.description }}</p>

              <!-- <span v-if="attention.behavioralExpression.tip">
                <p><strong>Tip</strong></p>
                <p>{{ attention.behavioralExpression.tip }}</p>
              </span> -->
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="pdf"
        class="page-title"
      >
        <h2>
          {{ content.title }} (page 2/2)
        </h2>
      </div>
      <div class="flex gap-14 gap-y-14">
        <div
          class="w-2/4 my-8 single-chart short-term"
        >
          <div class="box-title">
            Short term & work memory
          </div>
          <div class="flex items-center p-4">
            <div class="left-bar">
              <bar-chart
                data-measure="memory"

                v-if="chartData.memory"
                :height="chartHeight"

                :chart-data="workingMemory"
                :options="chartOptionsSeperate"
              />
            </div>
            <div
              v-if="workingMemory.behavioralExpression"
              class="w-2/3 p-4"
            >
              <p><strong>Expression</strong></p>
              <p>{{ workingMemory.behavioralExpression.description }}</p>

              <!-- <span v-if="workingMemory.behavioralExpression.tip">
                <p><strong>Tip</strong></p>
                <p>{{ workingMemory.behavioralExpression.tip }}</p>
              </span> -->
            </div>
          </div>
        </div>
        <div
          class="w-2/4 my-8 single-chart impulse-control"
        >
          <div class="box-title">
            Impulse Control
          </div>
          <div class="flex items-center p-4">
            <div
              class="left-bar"
            >
              <bar-chart
                data-measure="impulseControl"

                v-if="chartData.impulseControl"
                :height="chartHeight"

                :chart-data="impulseControl"
                :options="chartOptionsSeperate"
              />
            </div>
            <div
              v-if="impulseControl.behavioralExpression"
              class="w-2/3 p-4"
            >
              <p><strong>Expression</strong></p>
              <p>{{ impulseControl.behavioralExpression.description }}</p>

              <!-- <span v-if="impulseControl.behavioralExpression.tip">
                <p><strong>Tip</strong></p>
                <p>{{ impulseControl.behavioralExpression.tip }}</p>
              </span> -->
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-14 gap-y-14">
        <div class="w-2/4 my-8 single-chart creativity-flexibility">
          <div class="box-title">
            Creativity & Cognitive Flexibility
          </div>
          <div class="flex items-center p-4">
            <div class="left-bar">
              <bar-chart
                data-measure="creativityCognitiveFlexibility"
                v-if="chartData.creativityCognitiveFlexibility"
                :height="chartHeight"
                :chart-data="creativityCognitiveFlexibility"
                :options="chartOptionsSeperate"
              />
            </div>
            <div
              v-if="creativityCognitiveFlexibility.behavioralExpression"
              class="w-2/3 p-4"
            >
              <p><strong>Expression</strong></p>
              <p>{{ creativityCognitiveFlexibility.behavioralExpression.description }}</p>

              <!-- <span v-if="creativityCognitiveFlexibility.behavioralExpression.tip">
                  <p><strong>Tip</strong></p>
                  <p>{{ creativityCognitiveFlexibility.behavioralExpression.tip }}</p>
                </span> -->
            </div>
          </div>
        </div>
        <div class="w-2/4 my-8 single-chart strategic-think">
          <div class="box-title">
            Strategic Thinking
          </div>
          <div class="flex items-center p-4">
            <div class="self-end left-bar">
              <bar-chart
                data-measure="strategicThinking"
                v-if="chartData.strategicThinking"
                :height="chartHeight"
                :chart-data="strategicThinking"
                :options="chartOptionsSeperate"
              />
            </div>
            <div
              v-if="strategicThinking.behavioralExpression"
              class="w-2/3 p-4"
            >
              <p><strong>Expression</strong></p>
              <p>{{ strategicThinking.behavioralExpression.description }}</p>
              <!-- <span v-if="strategicThinking.behavioralExpression.tip">
                  <p><strong>Tip</strong></p>
                  <p>{{ strategicThinking.behavioralExpression.tip }}</p>
                </span> -->
            </div>
          </div>
        </div>
      </div>
      <transition appear>
        <div
          class="absolute z-50 w-96 p-2 bg-white rounded-md shadow pointer-events-none"
          :style="measureTooltipStyle"
          v-if="showExternalToolip"
        >
          <span v-if="showTooltipPos">2 points or more may suggest a slow starter, someone who wants to understand the task before accelerating or someone who performs best under pressure.</span>
          <span v-else>A major decrease may suggest someone that is sensitive to time-pressure or more complex tasks.</span>
        </div>
      </transition>
    </div>

    <!-- show for individual reports -->
    <!-- @Erik this is the new section we can show for individual reports -->
    <div
      v-else
      :class="{'breaker': !showSecondPagePdf}"
    >
      <div
        class="flex flex-col"
      >
        <div
          v-if="chartData.attention"
          class="w-full single-chart attention"
          :class="{'my-8': !pdf}"
        >
          <div class="box-title">
            Attention
          </div>
          <div class="flex items-center p-4">
            <div class="mr-20 left-bar">
              <bar-chart
                data-measure="attention"

                v-if="chartData.attention"
                :height="chartHeight"

                :chart-data="attention"
                :options="chartOptionsSeperate"
              />
            </div>
            <div
              v-if="attention.behavioralExpression"
              class="w-2/3 p-4"
            >
              <p><strong>Expression</strong></p>
              <p>{{ attention.behavioralExpression.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="pdf && showSecondPagePdf"
        class="page-title"
      >
        <h2>
          {{ content.title }} (page 2/2)
        </h2>
      </div>
      <div
        class="flex flex-col"
      >
        <div
          class="w-full single-chart short-term"
          :class="{'my-8': !pdf}"

          v-if="chartData.memory"
        >
          <div class="box-title">
            Short term & work memory
          </div>
          <div class="flex items-center p-4">
            <div class="mr-20 left-bar">
              <bar-chart
                data-measure="memory"

                v-if="chartData.memory"
                :height="chartHeight"

                :chart-data="workingMemory"
                :options="chartOptionsSeperate"
              />
            </div>
            <div
              v-if="workingMemory.behavioralExpression"
              class="w-2/3 p-4"
            >
              <p><strong>Expression</strong></p>
              <p>{{ workingMemory.behavioralExpression.description }}</p>
            </div>
          </div>
        </div>
        <div
          class="w-full single-chart impulse-control"
          :class="{'my-8': !pdf}"

          v-if="chartData.impulseControl"
        >
          <div
            class="box-title"
          >
            Impulse Control
          </div>
          <div class="flex items-center p-4">
            <div class="mr-20 left-bar">
              <bar-chart
                data-measure="impulseControl"

                v-if="chartData.impulseControl"
                :height="chartHeight"

                :chart-data="impulseControl"
                :options="chartOptionsSeperate"
              />
            </div>
            <div
              v-if="impulseControl.behavioralExpression"
              class="w-2/3 p-4"
            >
              <p><strong>Expression</strong></p>
              <p>{{ impulseControl.behavioralExpression.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col">
        <div
          v-if="chartData.creativityCognitiveFlexibility"
          :class="{'my-8': !pdf}"
          class="w-full single-chart creativity-flexibility"
        >
          <div class="box-title">
            Creativity & Cognitive Flexibility
          </div>
          <div class="flex items-center p-4">
            <div class="mr-20 left-bar">
              <bar-chart
                data-measure="creativityCognitiveFlexibility"
                v-if="chartData.creativityCognitiveFlexibility"
                :height="chartHeight"
                :chart-data="creativityCognitiveFlexibility"
                :options="chartOptionsSeperate"
              />
            </div>
            <div
              v-if="creativityCognitiveFlexibility.behavioralExpression"
              class="w-2/3 p-4"
            >
              <p><strong>Expression</strong></p>
              <p>{{ creativityCognitiveFlexibility.behavioralExpression.description }}</p>
            </div>
          </div>
        </div>
        <div
          class="w-full single-chart strategic-think"
          :class="{'my-8': !pdf}"
          v-if="chartData.strategicThinking"
        >
          <div class="box-title">
            Strategic Thinking
          </div>
          <div class="flex items-center p-4">
            <div class="self-end mr-20 left-bar">
              <bar-chart
                data-measure="strategicThinking"
                v-if="chartData.strategicThinking"
                :height="chartHeight"
                :chart-data="strategicThinking"
                :options="chartOptionsSeperate"
              />
            </div>
            <div
              v-if="strategicThinking.behavioralExpression"
              class="w-2/3 p-4"
            >
              <p><strong>Expression</strong></p>
              <p>{{ strategicThinking.behavioralExpression.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <transition appear>
        <div
          class="absolute z-50 w-96 p-2 bg-white rounded-md shadow pointer-events-none"
          :style="measureTooltipStyle"
          v-if="showExternalToolip"
        >
          <span v-if="showTooltipPos">2 points or more may suggest a slow starter, someone who wants to understand the task before accelerating or someone who performs best under pressure.</span>
          <span v-else>A major decrease may suggest someone that is sensitive to time-pressure or more complex tasks.</span>
        </div>
      </transition>
    </div>

    <transition appear>
      <div
        class="absolute z-50 w-96 p-2 bg-white rounded-md shadow pointer-events-none"
        :style="measureTooltipStyle"
        v-if="showExternalToolip"
      >
        <span v-if="showTooltipPos">2 points or more may suggest a slow starter, someone who wants to understand the task before accelerating or someone who performs best under pressure.</span>
        <span v-else>A major decrease may suggest someone that is sensitive to time-pressure or more complex tasks.</span>
      </div>
    </transition>
  </div>
</template>

<script>

import { PerformanceComplexity } from '@/content/reportContent.json'

import { BarChart } from 'vue-chart-3'
import QuestionIcon from './QuestionIcon.vue'
import { attentionColor, workingMemoryColor, impulseControlColor, creativityCognitiveFlexibilityColor, strategicThinkingColor, estimateColor } from '../chartConfig'

import { mapGetters } from 'vuex'

export default {
  components: {
    BarChart,
    QuestionIcon
  },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    pdf: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      content: PerformanceComplexity,
      missingMeasures: false,
      showExternalToolip: false,
      showTooltipPos: false,
      chartWidth: 200,
      chartHeight: this.pdf ? 500 : 600,
      measureTooltipStyle: {
        left: 0,
        top: 0
      },
      datasetOptions: {
        categoryPercentage: 1,
        barPercentage: 0.95

      },
      chartOptionsSeperate: {
        interaction: {
          mode: 'interpolate',
          intersect: false,
          axis: 'x'
        },
        plugins: {
          tooltip: {
            enabled: false,
            external: this.externalTooltipHandler
          },
          title: {
            display: false,
            position: 'bottom',
            font: {
              size: 16,
              style: 'normal',
              weight: 400

            },
            color: 'black'
          },
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            anchor: 'end',
            align: 'end',
            font: {
              size: 14
            }
          }
        },
        animation: (this.pdf) ? {
          duration: 0
        } : undefined,
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            top: 30
          }
        },
        scales: {
          x: {
            ticks: {
              maxRotation: 0,
              minRotation: 0,
              z: 1,
              mirror: true,
              font: {
                size: 14
              },
              color: 'white',
              callback: function (value) {
                const label = this.getLabelForValue(value)
                if (label.includes('&')) {
                  const split = label.split('&')
                  split[0] = `${split[0]} &`
                  return split
                }
                return label
              }
            },
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false,
              drawBorder: false
            },
            beginAtZero: true,
            max: 9,
            ticks: {
              display: false
            }
          }
        },
        datasets: {
          line: {
            pointRadius: 4,
            pointBackgroundColor: context => {
              const difference = context.dataset.data.reduce((a, b) => Math.abs(a - b), 0)
              if (difference >= 2) {
                return 'red'
              }
              return estimateColor
            },
            borderColor: context => {
              const difference = context.dataset.data.reduce((a, b) => Math.abs(a - b), 0)
              if (difference >= 2) {
                return 'red'
              }
              return estimateColor
            }
          }
        }
      }
    }
  },

  methods: {
    externalTooltipHandler  (context) {
      const { chart, tooltip } = context

      const difference = this.chartData[chart.canvas.offsetParent.dataset.measure].hard - this.chartData[chart.canvas.offsetParent.dataset.measure].easy

      const isPos = difference > 0

      // Hide if no tooltip
      if (tooltip.opacity === 0 || Math.abs(difference) < 2) {
        this.showExternalToolip = false
        return
      }

      this.showExternalToolip = true
      this.showTooltipPos = isPos
      const position = chart.canvas.getBoundingClientRect()
      this.measureTooltipStyle.left = position.left + tooltip.caretX + window.pageXOffset + 'px'
      this.measureTooltipStyle.top = position.top + tooltip.caretY + window.pageYOffset + 'px'
    },
    createChartOptions (title) {
      return {
        interaction: {
          mode: 'interpolate',
          intersect: false,
          axis: 'x'
        },
        plugins: {
          tooltip: {
            enabled: false,
            displayColors: false,
            external: this.externalTooltipHandler
          },
          title: {
            display: true,
            position: 'bottom',
            font: {
              size: 13,
              style: 'normal',
              weight: 400

            },
            text: title,
            color: 'black'
          },
          legend: {
            display: false
          },
          datalabels: {
            display: true,
            anchor: 'end',
            align: 'end',
            font: {
              size: 14
            }
          }

        },
        responsive: true,
        maintainAspectRatio: true,
        layout: {
          padding: {
            top: 30
          }
        },
        scales: {
          x: {
            ticks: {
              display: false

            },
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false,
              drawBorder: false
            },
            beginAtZero: true,
            max: 9,
            ticks: {
              display: false
            }
          }
        },
        datasets: {
          line: {
            pointRadius: 2,
            borderWidth: context => {
              const difference = context.dataset.data.reduce((a, b) => Math.abs(a - b), 0)
              if (difference >= 2) {
                return 3
              }
              return 0
            },
            borderColor: context => {
              const difference = context.dataset.data.reduce((a, b) => Math.abs(a - b), 0)
              if (difference >= 2) {
                return 'red'
              }
              return estimateColor
            }
          }
        }
      }
    },
    createDataset (key) {
      const colors = {
        attention: attentionColor,
        memory: workingMemoryColor,
        impulseControl: impulseControlColor,
        creativityCognitiveFlexibility: creativityCognitiveFlexibilityColor,
        strategicThinking: strategicThinkingColor
      }

      if (!this.chartData[key]) {
        this.missingMeasures = true

        return [
          {
            data: [0, 0],
            backgroundColor: colors[key],
            ...this.datasetOptions,
            order: 1

          },
          {
            data: [0, 0],
            borderColor: estimateColor,
            type: 'line',
            datalabels: {
              display: false
            },
            order: 0
          }
        ]
      } else {
        return [
          {
            data: [this.chartData[key].easy, this.chartData[key].hard],
            backgroundColor: colors[key],
            ...this.datasetOptions,
            order: 1

          },
          {
            data: [this.chartData[key].easy, this.chartData[key].hard],
            type: 'line',
            datalabels: {
              display: false
            },
            order: 0
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({ behavioralExpressions: 'REPORT/getBehavioralExpressions' }),
    attention () {
      return {
        labels: ['Easy', 'Demanding'],
        datasets: this.createDataset('attention'),
        behavioralExpression: this.behavioralExpressions.find(el => el.measure1 === 'simple_attention')
      }
    },
    workingMemory () {
      return {
        labels: ['Easy', 'Demanding'],
        datasets: this.createDataset('memory'),
        behavioralExpression: this.behavioralExpressions.find(el => el.measure1 === 'short_term_memory')
      }
    },
    impulseControl () {
      return {
        labels: ['Easy', 'Demanding'],
        datasets: this.createDataset('impulseControl'),
        behavioralExpression: this.behavioralExpressions.find(el => el.measure1 === 'impulse_control_easy')

      }
    },
    creativityCognitiveFlexibility () {
      return {
        labels: ['Easy', 'Demanding'],
        datasets: this.createDataset('creativityCognitiveFlexibility'),
        behavioralExpression: this.behavioralExpressions.find(el => el.measure1 === 'creativity_cognitive_flexibility_easy')

      }
    },
    strategicThinking () {
      return {
        labels: ['Easy', 'Demanding'],
        datasets: this.createDataset('strategicThinking'),
        behavioralExpression: this.behavioralExpressions.find(el => el.measure1 === 'strategic_thinking_easy')

      }
    },
    average () {
      const diffs = []
      const datasets = [this.attention, this.workingMemory, this.impulseControl, this.creativityCognitiveFlexibility, this.strategicThinking]
      datasets.forEach(data => {
        if (data) {
          const dataset = data.datasets[0]
          // DEMANDING score / EASY score - 1
          diffs.push(dataset.data[1] / dataset.data[0] - 1)
        }
      })
      const sum = diffs.reduce((total, current, index, arr) => total + current, 0)
      return Math.round(100 * sum / diffs.length)
    },
    level () {
      function score (diff) {
        if (Math.abs(diff) >= 1) {
          return Math.sign(diff)
        } else {
          return 0
        }
      }
      const diffs = []

      const datasets = [this.attention || null, this.workingMemory || null, this.impulseControl || null, this.creativityCognitiveFlexibility || null, this.strategicThinking || null]
      datasets.forEach(data => {
        if (data) {
          const dataset = data.datasets[0]
          // DEMANDING score - EASY score
          diffs.push(dataset.data[1] - dataset.data[0])
        }
      })
      const scores = diffs.map(score)
      const sum = scores.reduce((total, current, index, arr) => total + current, 0)
      let level
      if (sum === 4 || sum === 5) {
        level = 5
      } else if (sum === 2 || sum === 3) {
        level = 4
      } else if (sum === 1 || sum === 0 || sum === -1) {
        level = 3
      } else if (sum === -2 || sum === -3) {
        level = 2
      } else if (sum === -4 || sum === -5) {
        level = 1
      } else {
        level = 3
      }
      return level
    },
    showSecondPagePdf () {
      const datasets = [this.attention || null, this.workingMemory || null, this.impulseControl || null, this.creativityCognitiveFlexibility || null, this.strategicThinking || null].filter(el => !!el.behavioralExpression)

      return datasets.length > 2
    }
  }
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all .1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
