<template>
  <div class="flex flex-col w-full h-full p-5 candidate-match">
    <chart-tooltip
      ref="tooltip"
      type="spider"
      :has-play="true"
    />
    <div class="page-title">
      <h2>
        <span v-if="pdf || fromOtp">
          {{ content.pdfTitle }}

        </span>
        <span v-else>
          {{ content.title }}

        </span>
        <p class="text-xl">
          <question-icon
            class="float-right"
            style="display: none;"
            :questions="content.questions"
          />
        </p>
      </h2>
      <p v-if="pdf || fromOtp">
        {{ content.pdfDescription }}
      </p>
      <p v-else>
        {{ content.description }}
      </p>
    </div>
    <div class="flex items-center">
      <!-- Search box and compare candidate -->
      <!-- not shown if pdf or otp -->
      <div
        class="search-box"
        v-if="!pdf && !fromOtp"
      >
        <div
          class="flex flex-row w-full h-20 pr-2 mb-3"
          v-if="candidates.length > 0"
        >
          <multi-select
            class="w-full h-full"
            v-model="selectedRequirementSpecifications"
            :select-options="requirementSpecifications"
            :btn-label="btnLabel"
            :options="multiselectOptions"
          />
        </div>
        <div class="flex">
          Show candidate information
          <show-details-btn
            class="ml-2"
            v-model="showCanidateInformation"
          />
        </div>
        <div class="flex">
          Show detailed spider
          <show-details-btn
            class="ml-2"
            v-model="showDetailedSpider"
          />
        </div>
        <input
          type="text"
          v-model="search"
          placeholder="Search candidates..."
          class="p-2 border-2 rounded-lg search-input"
        >
        <ul class="flex flex-col overflow-y-auto h-96">
          <li
            v-for="candidate in candidates.filter(filterCandidates)"
            :key="candidate.user_id"
            class="mb-4"
          >
            <div class="flex flex-row items-center w-full">
              <div class="flex flex-row items-center w-full">
                <input
                  v-model="selectedCandidates"
                  :value="candidate.user_id"
                  type="checkbox"
                  class="mr-4 checkbox w-8 h-8"
                >
                {{ (candidate.name && showCanidateInformation) ? candidate.name : `Candidate ${candidate.alt_id}` }}
              </div>
              <div
                v-if="selectedRequirementSpecifications.length === 1 && candidateMatchingScores[candidate.user_id] >= 0"
                class="flex flex-row w-1/2 items-center"
              >
                <MatchingBox
                  :matching="candidateMatchingScores[candidate.user_id]"
                  :applicable="selectedRequirementSpecifications.length === 1"
                />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- Spider chart -->
      <div class="flex-auto w-64 mx-8 spider-box">
        <div class="w-52" />
        <div class="flex flex-col items-center">
          <div
            class="summary-box-profile-match"
            v-if="selectedMainCandidate"
          >
            <p class="text-sm font-bold">
              Total capacity score: {{ parseFloat(mainCandidate.total_capacity_score).toFixed(1) }}
            </p>
          </div>
          <radar-chart
            v-if="loadedTooltip"
            key="comparisonSpider"
            ref="chart"
            :width="725"
            :height="725"
            :chart-data="data"
            :options="options"
            :plugins="[$refs.tooltip.plugin]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AbilityComparisonRadar, Charts } from '@/content/reportContent.json'
import { RadarChart } from 'vue-chart-3'

import ChartTooltip from './ChartTooltip'
import QuestionIcon from './QuestionIcon.vue'
import ShowDetailsBtn from '@/modules/Scoreboard/components/ShowDetailsBtn.vue'
import MatchingBox from '@/components/requirement/MatchingBox.vue'
import MultiSelect from '@/components/reports/MultiSelect.vue'

import { candidateStyling, normStyling, footballStyling } from '@/utils/chartUtils.js'

import { mapGetters, mapState } from 'vuex'

// UNIQUE COLOR GENERATOR
function getUniqueColor (n) {
  const rgb = [0, 0, 0]

  for (let i = 0; i < 24; i++) {
    rgb[i % 3] <<= 1
    rgb[i % 3] |= n & 0x01
    n >>= 1
  }

  return '#' + rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, '')
}

// round value to nearest step interval (0.1 for one decimal, 0.5 for half-integer etc.)
function round (value, step) {
  const inv = 1.0 / step
  return Math.round(value * inv) / inv
}

// interpolated tick of given stanine value
function interpolateStanineTick (stanine) {
  if (stanine in stanineMapReversed) {
    return stanineMapReversed[stanine]
  }

  const xFloor = Math.floor(stanine)
  const xCeil = Math.ceil(stanine)

  const yFloor = stanineMapReversed[xFloor]
  const yCeil = stanineMapReversed[xCeil]

  const deltaX = xCeil - xFloor
  const deltaY = yCeil - yFloor

  // linear interpolation
  const tickInterpolated = yFloor + (stanine - xFloor) / deltaX * deltaY
  return round(tickInterpolated, 0.5)
}

// interpolated stanine value of given tick
function interpolateTickStanine (tick) {
  if (tick in stanineMap) {
    return stanineMap[tick]
  }

  let xFloor = 0
  let xCeil = 0

  // find smallest val s.t. val > tick (cannot be equal to since we already check if tick in stanineMap)
  stanineMapKeys.every(val => {
    xFloor = xCeil
    xCeil = val
    if (xCeil > tick) {
      return false // stop iterating
    }
    return true // keep iterating
  })

  const yFloor = stanineMap[xFloor]
  const yCeil = stanineMap[xCeil]

  const deltaX = xCeil - xFloor
  const deltaY = yCeil - yFloor

  // linear interpolation
  const tickInterpolated = yFloor + (tick - xFloor) / deltaX * deltaY
  return round(tickInterpolated, 0.1)
}

const stanineMap = {
  '-10': '',
  '-1': 1,
  7: 2,
  16: 3,
  31: 4,
  49.5: 5,
  68: 6,
  82.5: 7,
  92: 8,
  100: 9
}
const stanineMapReversed = {
  0: -30,
  1: -1,
  2: 7,
  3: 16,
  4: 31,
  5: 49.5,
  6: 68,
  7: 82.5,
  8: 92,
  9: 100
}

// sorted array of keys of stanineMap
const stanineMapKeys = Object.keys(stanineMap).map(x => Number(x)).sort((a, b) => a - b)

export default {
  components: { RadarChart, ChartTooltip, QuestionIcon, ShowDetailsBtn, MatchingBox, MultiSelect },
  props: {
    pdf: Boolean,
    chartData: {
      type: Array,
      required: true
    },
    fromOtp: {
      type: Boolean,
      default: false,
      required: false
    },
    footballProfiles: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      btnLabel: values => values.length === 0 ? 'No requirement specifications' : (values.map((spec) => spec.name).join(', ').length < 25 ? values.map((spec) => spec.name).join(', ') : `${values.length} requirement specification` + (values.length > 1 ? 's' : '')),
      multiselectOptions: {
        multi: true,
        cssSelected: (option) => option.selected ? { color: 'primary-light', 'font-size': '1.5rem', 'font-weight': 500 } : { 'font-size': '1.5rem', 'font-weight': 300 }
      },
      content: AbilityComparisonRadar,
      loadedTooltip: false,
      search: '',
      selectedRequirementSpecifications: [],
      showCanidateInformation: false,
      selectedCandidates: [],
      candidateMatchingScores: {},
      colors: ['#e11845', '#87e911', '#0057e9', '#ff00bd', '#f2ca19', '#8931ef', 'rgb(240,163,255)', 'rgb(0,117,220)',
        'rgb(153,63,0)', 'rgb(76,0,92)', 'rgb(25,25,25)', 'rgb(0,92,49)', 'rgb(43,206,72)',
        'rgb(255,204,153)', 'rgb(128,128,128)', 'rgb(148,255,181)', 'rgb(143,124,0)', 'rgb(157,204,0)',
        'rgb(194,0,136)', 'rgb(0,51,128)', 'rgb(255,164,5)', 'rgb(255,168,187)', 'rgb(66,102,0)'],
      requirementSpecificationColors: ['#054A29', '#62C370', '#14213D', '#FCA311'],
      showDetailedSpider: false,
      data: {
        labels: this.chartData.map(el => el.label),
        datasets: null
      },
      options: {
        layout: {
          padding: {
            right: 22,
            left: 22
          }
        },
        responsive: true,
        maintainAspectRatio: true,
        animation: (this.pdf) ? {
          duration: 0
        } : undefined,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              font: {
                size: 16
              }
            }
          },
          tooltip: {
            callbacks: {
              label: (ctx) => {
                return `${ctx.dataset.label}: ${interpolateTickStanine(ctx.parsed.r)}`
              }
            }
          }
        },
        scales: {
          r: {
            grid: {
              lineWidth: 2
            },
            max: 100,
            min: -30,
            ticks: {
              showLabelBackdrop: false,
              padding: 0,
              stepSize: 0.5,
              z: 1,
              callback (tickValue) {
                return stanineMap[tickValue.toString()]
              },
              font: {
                size: 10
              }
            },
            pointLabels: {
              font: {
                size: 16,
                weight: 'bold'
              },
              color: label => {
                // color the labels depening on score. 4> green 3-4 yellow 2 < red
                if (this.data.datasets[0] && this.data.datasets.length === 2) {
                  const value = stanineMap[this.data.datasets[0].data[label.index].toString()]

                  if (value === 0) {
                    return 'black'
                  } if (value > 4) {
                    return 'green'
                  } else if (value === 3 || value === 4) {
                    return '#f1ca19' // yellow
                  } else if (value < 3) {
                    return 'red'
                  } else {
                    return 'black'
                  }
                } else {
                  return 'black'
                }
              },
              callback: label => {
                if (label.includes('&')) {
                  const split = label.split('&')
                  split[0] = split[0] + ' &'
                  return split
                }
                return label
              }
            }
          }
        }
      }
    }
  },
  async created () {
    // disable if otp
    if (!this.fromOtp) {
      await this.$store.dispatch('REQUIREMENT_SPECIFICATIONS/getRequirementSpecifications')
    }
    this.selectedCandidates.push(this.mainCandidate.user_id)

    // if there are more candidates and requirement specifications than colors then generate new
    if (this.colors.length < this.candidates.length) {
      this.candidates.forEach((_, index) => this.colors.push(getUniqueColor(index)))
    }

    if (this.requirementSpecificationColors.length < this.requirementSpecifications.length) {
      this.requirementSpecifications.forEach((_, index) => this.requirementSpecificationColors.push(getUniqueColor(this.candidates.length + index)))
    }

    // If we are signed in as ADMIN
    if (this.isLoggedAdminIn) {
      this.colors.push(getUniqueColor(200))
    }
  },
  mounted () {
    this.loadedTooltip = true
  },
  watch: {
    showCanidateInformation () {
      this.createChartDatasets()
    },
    showDetailedSpider () {
      this.createChartDatasets()
    },
    async selectedCandidates () {
      // disable for otp - we only need to show main candidate
      if (!this.otp) {
        for (const candidate of this.selectedCandidates) {
          await this.$store.dispatch('REPORT/getResultsComparison', candidate)
        }
      }
      this.createChartDatasets()
    },
    async selectedRequirementSpecifications () {
      this.createChartDatasets()
    }
  },
  computed: {
    ...mapState('REPORT', ['candidates', 'results']),
    ...mapGetters({
      requirementSpecifications: 'REQUIREMENT_SPECIFICATIONS/getRequirementSpecifications',
      mainCandidate: 'REPORT/getMainCandidate',
      isLoggedAdminIn: 'ADMIN/isLoggedAdminIn'
    }),
    selectedMainCandidate () {
      return this.selectedCandidates.includes(this.mainCandidate.user_id) && this.selectedCandidates.length < 2
    }
  },
  methods: {
    createChartDatasets () {
      this.candidateMatchingScores = {}
      // parse requirements
      const reqMainMeasures = {}
      const mainMeasuresIOAverages = [] // averages of importance and occurence
      if (this.selectedRequirementSpecifications.length > 0) {
        this.selectedRequirementSpecifications.forEach(specification => {
          reqMainMeasures[specification.id] = []
          specification.Requirements.forEach(result => {
            const mainMeasure = Charts.MainMeasures[result.measure]
            if (mainMeasure) {
              reqMainMeasures[specification.id][mainMeasure.order] = result.stanine
              mainMeasuresIOAverages[mainMeasure.order] = Math.floor(1 / 2 * (result.importance + result.occurrence)) // to replicate what's happening in the backend
            }
          })

          for (const key in Charts.MainMeasures) {
            const index = Charts.MainMeasures[key].order
            if (!reqMainMeasures[specification.id][index]) {
              reqMainMeasures[specification.id][index] = 0
            }
          }
        })
      }

      this.data.datasets = Object.keys(this.results).filter(candidateId => this.selectedCandidates.includes(candidateId)).map(candidateId => {
        const candidateIndex = this.candidates.findIndex(el => el.user_id === candidateId)
        const color = this.colors[candidateIndex] || this.colors[this.colors.length - 1]

        const mainMeasures = []
        let matchSum = 0

        this.results[candidateId].forEach(result => {
          const mainMeasure = Charts.MainMeasures[result.measure]

          if (mainMeasure) {
            mainMeasures[mainMeasure.order] = result.stanine
            if (this.selectedRequirementSpecifications.length > 0) {
              matchSum += Math.min(1, result.stanine / mainMeasuresIOAverages[mainMeasure.order])
            }
          }
        })

        // formula: capacity / ((importance + occurrence) /2)
        // capacity scores > (importance + occurrence) /2 get set to (importance + occurrence) /2
        if (this.selectedRequirementSpecifications.length === 1) {
          this.candidateMatchingScores[candidateId] = matchSum / Object.values(reqMainMeasures)[0].length
        }

        for (const key in Charts.MainMeasures) {
          const index = Charts.MainMeasures[key].order
          if (!mainMeasures[index]) {
            mainMeasures[index] = 0
          }
        }

        let label

        if (candidateIndex === -1) {
          label = (this.mainCandidate.name && this.showCanidateInformation) ? this.mainCandidate.name : `Candidate ${this.mainCandidate.alt_id}`
        } else {
          label = (this.candidates[candidateIndex].name && this.showCanidateInformation) ? this.candidates[candidateIndex].name : `Candidate ${this.candidates[candidateIndex].alt_id}`
        }

        return {
          label,
          data: mainMeasures.map(val => this.showDetailedSpider ? interpolateStanineTick(val) : stanineMapReversed[round(val, 1)]),
          fill: false,
          backgroundColor: color,
          borderColor: color,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointHoverBackgroundColor: color,
          pointHoverBorderColor: color
        }
      })

      if (this.selectedRequirementSpecifications.length > 0) {
        this.selectedRequirementSpecifications.forEach(specification => {
          const color = this.requirementSpecificationColors[this.selectedRequirementSpecifications.indexOf(specification)]
          this.data.datasets.push({
            ...candidateStyling,
            label: specification.name,
            data: reqMainMeasures[specification.id].map(val => this.showDetailedSpider ? interpolateStanineTick(val) : stanineMapReversed[round(val, 1)]),
            backgroundColor: color,
            borderColor: color,
            pointBackgroundColor: color,
            pointBorderColor: color,
            pointHoverBackgroundColor: color,
            pointHoverBorderColor: color
          })
        })
      }
      // the norm
      this.data.datasets.push({
        label: 'The Norm',
        data: Array(Object.keys(Charts.MainMeasures).length).fill(5).map(val => stanineMapReversed[val]),
        ...normStyling
      })
      if (this.footballProfiles) {
      // Football Elite
        this.data.datasets.push({
          label: 'Football Elite',
          data: [6, 7, 6, 6, 6, 8, 5, 5].map(val => stanineMapReversed[val]),
          ...footballStyling.elite
        })
        // Football Semi-Elite
        this.data.datasets.push({
          label: 'Football Semi-Elite',
          data: [5, 5, 5, 5, 5, 6, 5, 5].map(val => stanineMapReversed[val]),
          ...footballStyling.semiElite
        })
        // Xavi
        this.data.datasets.push({
          label: 'Xavi',
          data: [7, 8, 5, 5, 6, 7, 5, 5].map(val => stanineMapReversed[val]),
          ...footballStyling.xavi
        })
        // Iniesta
        this.data.datasets.push({
          label: 'Iniesta',
          data: [7, 5, 5, 6, 7, 7, 5, 5].map(val => stanineMapReversed[val]),
          ...footballStyling.iniesta
        })
      }
    },
    filterCandidates (candidate) {
      const name = (candidate.name && this.showCanidateInformation) ? candidate.name : `Candidate ${candidate.alt_id}`
      return name.toLowerCase().includes(this.search.toLowerCase())
    }
  }

}
</script>
